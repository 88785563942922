import React, { useState, useRef } from "react";
import { Modal, Box, Typography, Grid, LinearProgress } from "@mui/material";
import Button from "../components/Common/Button";
import { useSelector } from "react-redux";
import {
  uploadSurveyHelpers,
  validateSurveyHelpersUploadFile,
  findBatchById,
  batchExceptions,
} from "./Survey/Survey.service";
import theme from "../theme";

const ImportStaffModal = ({
  importModalOpen,
  setImportModalOpen,
  fetchUsers,
}) => {
  const [fileErrors, setFileErrors] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);
  const [importing, setImporting] = useState(false);
  const [importMessage, setImportMessage] = useState("");
  const [fileExceptions, setFileExceptions] = useState([]);
  const intervalRef = useRef(null);
  const surveyId = useSelector((state) => state?.survey?.activeSurveys[0]?.id);
  const respondentId = useSelector(
    (state) => state.survey.respondentDetails?.id
  );
  const surveyOrgId = useSelector(
    (state) => state?.survey?.respondentDetails?.surveyOrganization?.id
  );
  const surveyFilingId = useSelector(
    (state) => state?.survey?.surveyFilingDto?.id
  );

  const handleClose = () => {
    setFileErrors([]);
    setFileExceptions([]);
    setImporting(false);
    setImportMessage("");
    setImportModalOpen(false);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    fetchUsers();
  };

  const ErrorList = ({ fileErrors }) => {
    const listItems = fileErrors.map((error, index) => (
      <li key={index}>{error}</li>
    ));
    return <ul>{listItems}</ul>;
  };

  const BatchExceptionsList = ({ errors }) => {
    const listItems = errors.map((error, index) => (
      <li key={index}>
        {"Record "}
        {error?.id} : {error?.exceptionType?.description}
      </li>
    ));
    return <ul>{listItems}</ul>;
  };

  const getBatchInfo = async (batchId) => {
    intervalRef.current = setInterval(async () => {
      try {
        const batchInfo = await findBatchById(batchId);
        if (batchInfo?.data?.endTime) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
          setImportMessage("Import complete!");
          try {
            const res = await batchExceptions(batchId);
            if (Array.isArray(res?.data) && res?.data?.length > 0) {
              const responseData = res?.data;
              setFileExceptions(responseData);
            }
          } catch (error) {
            console.error("Error fetching batch Exceptions:", error);
          }
        }
      } catch (error) {
        console.error("Error fetching batch info:", error);
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }, 2000);
  };

  const handleFileUpload = async (event) => {
    setLoadingFile(true);
    const fileInput = event.target;
    const file = fileInput.files[0];

    if (!file) {
      setLoadingFile(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await validateSurveyHelpersUploadFile(
        formData,
        surveyId,
        surveyFilingId
      );
      if (response?.status === 200) {
        const responseData = response?.data;
        if (responseData.fileErrors.length > 0) {
          setFileErrors(responseData.fileErrors);
        } else {
          setImporting(true);
          setImportMessage("Importing file...");
          const res = await uploadSurveyHelpers(
            formData,
            surveyId,
            surveyOrgId,
            surveyFilingId,
            respondentId
          );

          if (res?.status === 200) {
            const batchId = res.data?.id;
            if (batchId) {
              getBatchInfo(batchId);
            } else {
              setImporting(false);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error exporting:", error);
    } finally {
      setLoadingFile(false);
    }

    fileInput.value = "";
  };

  return (
    <Modal open={importModalOpen} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          width: "40%",
          maxHeight: "80%",
          boxShadow: 24,
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowY: "auto",
        }}
      >
        <Typography variant="body1" color="primary" fontWeight="bold">
          Import Staff
        </Typography>
        {!importing && !loadingFile && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              component="label"
              style={{ marginRight: "20px" }}
            >
              Select File
              <input
                type="file"
                hidden
                accept=".csv,.txt"
                onChange={(e) => {
                  setFileErrors([]);
                  setFileExceptions([]);
                  handleFileUpload(e);
                }}
              />
            </Button>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Close
            </Button>
          </Box>
        )}
        {loadingFile && (
          <Box sx={{ marginTop: "20px" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Validating file, please wait...
            </Typography>
            <LinearProgress />
          </Box>
        )}
        {!loadingFile && importing && (
          <Box sx={{ marginTop: "20px" }}>
            <Typography style={{ fontWeight: "bold" }}>
              {importMessage}
            </Typography>
            {importMessage === "Importing file..." && <LinearProgress />}
          </Box>
        )}
        {fileErrors?.length > 0 && (
          <Box sx={{ marginTop: "20px" }}>
            <Typography
              variant="subtitle2"
              color={theme.palette.error.main}
              sx={{ textDecoration: "underline" }}
            >
              ERRORS
            </Typography>
            <Typography>
              The following errors were found when validating the file. Please
              correct the errors and then resubmit the file.
            </Typography>
            <Grid item xs={true}>
              {<ErrorList fileErrors={fileErrors} />}
            </Grid>
          </Box>
        )}
        <Box>
          {fileExceptions && fileExceptions?.length > 0 && (
            <>
              <Typography
                variant="subtitle1"
                color={theme.palette.error.main}
                sx={{ textDecoration: "underline", paddingTop: "5px" }}
              >
                Errors
              </Typography>
              <Typography>The following errors occurred:</Typography>
              <Typography>Please correct the file and re-import.</Typography>
              <Grid item xs={true}>
                {<BatchExceptionsList errors={fileExceptions} />}
              </Grid>
            </>
          )}
          {!fileExceptions && (
            <Typography variant="body1">
              There are no batch exceptions for this batch
            </Typography>
          )}
        </Box>
        {(loadingFile || importing) && (
          <Box sx={{ marginTop: "20px" }}>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Close
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ImportStaffModal;
