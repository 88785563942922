import axios from "axios";
import {
  getIsAPILoggedIn,
  getLoginData,
} from "../pages/Survey/Survey.service.js";
import {
  checkLoginStatus,
  getLoginAuthenticated,
} from "../pages/Survey/Survey.slice.js";
import { APP_URL, BASE_URL, LOCAL_STORAGE_KEYS } from "./constants.js";

// const handleErrorResponse = async (err) => (err?.response ? err?.response : err);

axios.interceptors.response.use(
  (response) => {
    // If the response status is 302, handle it here
    if (
      response.status === 302 ||
      response.status === 404 ||
      response.status === 400
    ) {
      // Perform your operations or redirect logic here
      console.log("Received a 302 status code. Performing operations...");
      // Example: Redirect to the specified URL
      checkLogin();
    }

    // Return the response for other status codes
    return response;
  },
  (error) => {
    // Handle errors here
    // checkLogin();
    return Promise.reject(error);
  }
);

const checkLogin = async () => {
  const res = await getIsAPILoggedIn();
  if (res?.payload) {
    const response = await getLoginData();
    // navigate('/');
  } else {
    // handle unauthorised case.
    window.location.href = `${BASE_URL}/taker/ssoAnnualTakerLogin`;
    // window.location.href =  'http://localhost:8080/api/v1/taker/ssoAnnualTakerLogin';
  }
  // dispatch(checkLoginStatus()).then((res) => {
  //   console.log('the response of login api: ', res);
  //   if (res?.payload) {
  //     dispatch(getLoginAuthenticated());
  //     navigate('/');
  //   } else {
  //     // handle unauthorised case.
  //     window.location.href =  `${BASE_URL}/taker/ssoAnnualTakerLogin`;
  //     // window.location.href =  'http://localhost:8080/api/v1/taker/ssoAnnualTakerLogin';
  //   }
  // });
};

const logoutUser = async () => {
  localStorage.clear();
  window.location.reload();
};
const handleErrorResponseForToken = async (err) => {
  if (err.response) {
    if (err.response.status === 401) {
      //Add a toast or error display mechanism and logout
    }
  } else {
    return err;
  }
};
const handleErrorResponse = async (err) => {
  if (err.response) {
    console.log(
      "the error response is 8888 : ",
      err.response,
      process.env.REACT_APP_REACT_TAKER_URL_REDIRECT
    );
    if (err.response.status === 401) {
      //Add a toast or error display mechanism and logout
      logoutUser();
    } else if (err.response.status === 400) {
      window.location.href = `${process.env.REACT_APP_REACT_TAKER_URL_REDIRECT}`;
      // return err;
    }
  } else {
    return err;
  }
};

const handleSuccessResponse = (response) => {
  if (response?.redirected) {
    console.log("redirected to ", response?.url);
    document.location = response?.url;
  }
  return response;
};

const headersTokenObj = {
  "Content-Type": "application/x-www-form-urlencoded",
  "Access-Control-Allow-Origin": "*",
  "X-Requested-With": "JavaScript",
};

const headersObj = {
  "Content-Type": "application/json",
};

const returnTokenWithBearer = (token) => `Bearer ${token}`;

// get method

export const getData = async (url, params = {}) => {
  // localStorage.setItem(
  //   LOCAL_STORAGE_KEYS.TOKEN_KEY,
  //   "eyJhbGciOiJIUzI1NiJ9.eyJmaXJzdE5hbWUiOiJEZWVwYWsiLCJsYXN0TmFtZSI6Ikt1bWFyIiwidXNlcklkIjoiNjc5OSIsImVtYWlsIjoiZGtAYWJjLmNvbSIsInN1YiI6ImRrMTIzIiwiaWF0IjoxNjkyNjA4Njc2LCJleHAiOjE2OTI2OTUwNzd9.-IUJf2cnq1PBnj-4b3CYYKiHOLPmVE-Y5S8nu-JRofo"
  // );

  //  headersObj.Authorization = returnTokenWithBearer(
  //   localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN_KEY)
  // );

  const additionalHeaders = {
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "JavaScript",
    // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
  };

  const headers = {
    ...headersObj,
    ...additionalHeaders,
  };

  return axios
    .get(url, {
      withCredentials: true,
      headers: headers,
    })
    .then((res) => handleSuccessResponse(res))
    .catch((err) => handleErrorResponse(err, { type: "GET", url }));

  // let response = await fetch(url, { method: 'GET', redirect: "follow", credentials: "include", headers: headers}).then((response) => response);

  // if (response?.redirected) {
  //   console.log('redirected to ', response?.url);
  //   document.location = response?.url;
  // }
  // const json = await response.json();
  // console.log('returning json', json);
  // return json;
};

// post method

export const postData = (url, body, isLogin = false) => {
  // headersObj.Authorization = returnTokenWithBearer(
  //   localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN_KEY)
  // );

  return axios
    .post(url, body, {
      withCredentials: true,
      headers: headersObj,
    })
    .then((res) => handleSuccessResponse(res))
    .catch((err) => handleErrorResponse(err), { type: "POST", url, body });
};

export const postFormData = (url, body, isLogin = false) => {
  const headersObj = {
    "Content-Type": "multipart/form-data",
  };

  return axios
    .post(url, body, {
      withCredentials: true,
      headers: headersObj,
    })
    .then((res) => handleSuccessResponse(res))
    .catch((err) => handleErrorResponse(err), { type: "POST", url, body });
};

export const postDataWithOutAuth = (url, body, isLogin = false) => {
  headersObj.Authorization = returnTokenWithBearer(
    localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN_KEY)
  );

  return axios
    .post(url, body)
    .then((res) => handleSuccessResponse(res))
    .catch((err) => handleErrorResponse(err), { type: "POST", url, body });
};

export const deleteData = (url, body, isLogin = false) => {
  headersObj.Authorization = returnTokenWithBearer(
    localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN_KEY)
  );

  return axios
    .post(url, body, {
      headers: headersObj,
    })
    .then((res) => handleSuccessResponse(res))
    .catch((err) => handleErrorResponse(err), { type: "POST", url, body });
};

export const putData = (url, body, isLogin = false) => {
  headersObj.Authorization = returnTokenWithBearer(
    localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN_KEY)
  );

  return axios
    .put(url, body, {
      headers: headersObj,
      withCredentials: true,
    })
    .then((res) => handleSuccessResponse(res))
    .catch((err) => handleErrorResponse(err), { type: "PUT", url, body });
};

export const postTokenData = (url, body, isLogin = false) => {
  // headersTokenObj.Authorization = returnTokenWithBearer(localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN_KEY));
  const head = "";
  return axios
    .post(url, body, {
      headers: headersTokenObj,
    })
    .then((res) => handleSuccessResponse(res))
    .catch((err) => handleErrorResponseForToken(err), {
      type: "POST",
      url,
      body,
    });
};

export const deleteMethod = (url, body, isLogin = false) => {
  headersObj.Authorization = returnTokenWithBearer(
    localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN_KEY)
  );

  return axios
    .delete(url, {
      headers: headersObj,
      data: body,
      withCredentials: true,
    })
    .then((res) => handleSuccessResponse(res))
    .catch((err) => handleErrorResponse(err), { type: "DELETE", url, body });
};
